import React, { Component } from "react";
import { Auth, API } from "aws-amplify";

import LoaderButton from "../components/LoaderButton";
import "./Login.css";
import { configAmplify } from "../utils/configUtils";

export default class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: "",
      password: "",
      confirmationCode: "",
      region: "-1",
      isLoading: false,
      errormessage: "",
      newUser: null,
      contact_support: "",
      showPassword: false,
    };
    this.validateForm = this.validateForm.bind(this);
  }

  componentDidMount = async () => {
    // check whether there's an updated service-worker
    navigator?.serviceWorker?.getRegistrations()?.then((registrationsArray) => {
      if (registrationsArray.length > 0) {
        registrationsArray[0]?.update();
      }
    });

    await Auth.currentSession()
      .then(async (session) => {
        //window.location.href = "/dashboard"
        this.setState({ isLoading: true });
        this.props.userHasAuthenticated(true);
        const user = await this.updateLogin();
        if (user.status) {
          window.location.href = "/dashboard";
        } else {
          await Auth.signOut();
          this.setState({
            isLoading: false,
            errormessage: "Attribut updated please try again.",
          });
        }
      })
      .catch(async (err) => {
        await Auth.signOut();
        this.setState({ isLoading: false });
        console.log("session erro.", err);
      });

    try {
      var res = await API.get("freelancer-portal", "/config/get-support-email");
      if (res.status) {
        this.setState({ contact_support: res.support_email });
      }
      console.log("/config/get-support-email", res);
    } catch (e) {
      console.log("/config/get-support-email erro.", e);
    }
  };

  validateForm() {
    return (
      this.state.email.length > 0 &&
      this.state.email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i) &&
      this.state.password.length > 0 &&
      this.state.region !== "-1"
    );
  }

  validateConfirmationForm() {
    return this.state.confirmationCode.length > 0;
  }

  handleChange = (event) => {
    this.setState({
      [event.target.id]: event.target.value,
    });
  };

  handleSubmit = async (event) => {
    event.preventDefault();
    this.setState({ isLoading: true });
    try {
      this.setState({ errormessage: "" });

      configAmplify(this.state.region);

      await Auth.signIn(this.state.email.toLowerCase(), this.state.password);
      this.props.userHasAuthenticated(true);

      const authUser = await Auth.currentAuthenticatedUser();

      if (!authUser.attributes.phone_number_verified) {
        // need to verify phone number
        this.props.history.push({
          pathname: "/verify",
          search: "?resend=true",
        });
        return;
      }

      const user = await this.updateLogin();

      if (user.status) {
        let requestedId = this.props.location.state?.id;
        console.log("requestedId", requestedId);
        if (requestedId) {
          this.props.history.push({
            pathname: "/dashboard",
            search: `?id=${requestedId}`,
          });
        } else {
          this.props.history.push("/dashboard");
        }
      } else {
        await Auth.signOut();
        if (user.error === "CustomAttributsUpdated") {
          this.handleSubmit();
        } else {
          this.setState({ isLoading: false });
          this.setState({
            isLoading: false,
            errormessage: "INCORRECT USERNAME OR PASSWORD",
          });
          console.log("update user login=>", user);
        }
      }
    } catch (e) {
      console.log(e.name);
      console.log("Login Error=>", e);
      if (e.name === "UserNotConfirmedException") {
        this.setState({ newUser: true });
      }
      await Auth.signOut();
      //this.setState({ isLoading: false, errormessage:e.message });
      this.setState({
        isLoading: false,
        errormessage: "INCORRECT USERNAME OR PASSWORD",
      });
    }
  };

  handleConfirmationSubmit = async (event) => {
    event.preventDefault();

    this.setState({ isLoading: true });

    try {
      await Auth.confirmSignUp(
        this.state.email.toLowerCase(),
        this.state.confirmationCode
      );

      await Auth.signIn(this.state.email.toLowerCase(), this.state.password);
      this.props.userHasAuthenticated(true);
      const user = await this.updateLogin();
      if (user.status) {
        // window.location.href = "/dashboard"
        //window.location.href = "/add-signature";
        this.props.history.push({
          pathname: "/add-signature",
          state: this.state.email,
        });
      } else {
        await Auth.signOut();
        await Auth.signIn(this.state.email.toLowerCase(), this.state.password);
        this.props.userHasAuthenticated(true);
        this.props.history.push({
          pathname: "/add-signature",
          state: this.state.email,
        });
        //window.location.href = "/";
      }
    } catch (e) {
      alert(e.message);
      this.setState({ isLoading: false });
    }
  };

  handleResendConfirmationCode = async (event) => {
    event.preventDefault();

    this.setState({ isLoading: true });

    try {
      await Auth.resendSignUp(this.state.email.toLowerCase());
      //alert('Code resent succsessfully. Please check your cell phone for SMS.');
      this.setState({ isLoading: false });
    } catch (e) {
      alert(e.message);
      this.setState({ isLoading: false });
    }
  };

  updateLogin() {
    return this.props.postRequest("/users/update-login", {
      userEmail: this.state.email.toLowerCase(),
    });
  }

  renderConfirmationForm() {
    return (
      <div className="col-sm-12 forgot_bg">
        <div className="container p0">
          <div className="forgot_box">
            <a href="/login">
              <img
                src="images/ic_chevron_left1_24px.svg"
                className="back_forgot_circle"
                width="15"
                height="23"
                alt=""
              />
            </a>
            <div className="clear10"></div>
            <div className="forgot_circle">
              <img src="images/ic_lock1_outline_24px.svg" alt="" />
            </div>
            <h2> Verify Account</h2>
            <div className="clear10"></div>
            If you didn't receive the PIN Code, click on "Resend",
            <br />
            otherwise fill in the code and click on "Verify".
            <div className="register_box_mid">
              <div className="clear20"></div>

              <form onSubmit={this.handleConfirmationSubmit}>
                <div className="col-sm-12 p0">
                  <input
                    name=""
                    id="confirmationCode"
                    className="frogot_input"
                    type="text"
                    placeholder="PIN Code"
                    value={this.state.confirmationCode}
                    onChange={this.handleChange}
                  />
                  <img
                    src="images/ic_vpn_key_24px.svg"
                    width="25px"
                    height="15px"
                    className="register_icon1"
                    alt=""
                  />
                </div>
                <div className="clear10"></div>
                <div className="col-sm-12 text-right">
                  {" "}
                  <a
                    href="javascript:void(0)"
                    onClick={this.handleResendConfirmationCode}
                    className="resend_code"
                  >
                    Resend code
                  </a>
                </div>
                <div className="clear10"></div>
                <LoaderButton
                  block
                  bsSize="large"
                  disabled={!this.validateConfirmationForm()}
                  type="submit"
                  isLoading={this.state.isLoading}
                  text="Verify"
                  loadingText="Verifying…"
                  className="btn_forogot"
                />
              </form>
              <div className="clear10"></div>
            </div>
          </div>
          <div className="clear10"></div>
        </div>
      </div>
    );
  }

  // render login form
  renderForm() {
    return (
      <div className="login_form_container">
        <div className="col-sm-12 full_view login_container_parent">
          <div className="container p0 login_container">
            <div className="col-sm-6 text-left middle_sep">
              <a href="/">
                <img src="images/logo.png" className="logo_left" alt="" />{" "}
              </a>
              <h2 className="welcome_heading">Welcome to Freelance Portal</h2>
              <p className="welcome_label">
                > Create and submit digital timecards for any production <br />>
                Complete Digital Forms contracts faster by saving your data{" "}
                <br />
                > Receive and store your TPH payslip securely <br />> Find out
                breaking industry news
              </p>
              <h2 className="welcome_heading">Our Expertise</h2>
              <div className="col-xs-6 col-sm-3 icon_1_acess">
                <img src="images/access.png" width="45" height="50" alt="" />{" "}
                <br />
                Access
              </div>
              <div className="col-xs-6 col-sm-3 icon_1_acess">
                <img src="images/security.png" width="45" height="50" alt="" />{" "}
                <br />
                Security
              </div>
              <div className="col-xs-6 col-sm-3 icon_1_acess">
                <img src="images/user1.png" width="45" height="50" alt="" />
                <br />
                User
              </div>
              <div className="col-xs-6 col-sm-3 icon_1_acess">
                <img src="images/timecard.png" width="45" height="50" alt="" />
                <br />
                TimeCard
              </div>
              <div className="clear20"></div>
              <p className="welcome_label">
                Contact Support:{" "}
                <a href={"mailto:" + this.state.contact_support}>
                  {this.state.contact_support}
                </a>
              </p>
            </div>

            <div className="col-sm-6 col-md-4 col-md-offset-1 p0">
              <div className="login_box">
                <h2>
                  <img src="images/login.png" alt="" /> Log in
                </h2>
                <div className="clear20"></div>

                {!this.state.errormessage ? (
                  ""
                ) : (
                  <div>
                    <div className="clear20"></div>
                    <div className="alert alert-danger text-uppercase">
                      {this.state.errormessage}
                    </div>
                  </div>
                )}

                <form onSubmit={this.handleSubmit}>
                  <div className="col-sm-12 p0">
                    <input
                      name=""
                      id="email"
                      className="login_input"
                      type="text"
                      onChange={this.handleChange}
                      placeholder="Email Address"
                      defaultValue=""
                    />
                    <img src="images/email.png" className="user_icon" alt="" />
                  </div>
                  <div className="clear20"></div>
                  <div className="col-sm-12 p0">
                    <input
                      name=""
                      id="password"
                      className="login_input"
                      type={this.state.showPassword ? "text" : "password"}
                      onChange={this.handleChange}
                      placeholder="Password"
                      defaultValue=""
                    />
                    <img src="images/psw.png" className="user_icon" alt="" />
                    {!this.state.showPassword && (
                      <img
                        src="images/signup/eye.svg"
                        className="pwd-mask-icon"
                        alt="Show password"
                        onClick={() => this.setState({ showPassword: true })}
                      />
                    )}
                    {this.state.showPassword && (
                      <img
                        src="images/signup/eye-off.svg"
                        className="pwd-mask-icon"
                        alt="Show password"
                        onClick={() => this.setState({ showPassword: false })}
                      />
                    )}
                  </div>
                  <div className="clear20"></div>
                  <div className="col-sm-12 p0">
                    <select
                      name="region"
                      id="region"
                      className="region-select"
                      onChange={this.handleChange}
                    >
                      <option value="-1">Select your region</option>
                      <option value="AU">Australia</option>
                      <option value="UK">United Kingdom</option>
                    </select>
                  </div>
                  <div className="clear15"></div>
                  <div className="col-sm-12 p0 text-right">
                    <a href="/forgot" className="login_forgot">
                      Forgot Password?
                    </a>
                  </div>
                  <div className="clear20"></div>

                  <LoaderButton
                    block
                    bsSize="large"
                    disabled={!this.validateForm()}
                    type="submit"
                    isLoading={this.state.isLoading}
                    text="Login"
                    loadingText="Logging in…"
                    className="btn_login"
                  />
                </form>
                <div className="col-xs-6 p0 text-left">
                  <a href="/register" className="login_forgot">
                    Create Acccount
                  </a>
                </div>
                <div className="col-xs-6 p0 text-right">
                  <a href="/need-help" className="login_forgot">
                    Need Help?
                  </a>
                </div>
                <div className="clear20"></div>
                <div className="strike">
                  <span>or</span>
                </div>
                <div className="clear20"></div>
                <div className="col-sm-12 text-center register">
                  Login with social media
                </div>
                <div className="clear20"></div>
                <div className="col-xs-2 col-sm-offset-4">
                  <button
                    className="socialbuttons"
                    onClick={() =>
                      Auth.federatedSignIn({ provider: "Facebook" })
                    }
                  >
                    <img src="images/fb.png" width="35" height="35" alt="" />
                  </button>
                </div>
                <div className="col-xs-2">
                  <button
                    className="socialbuttons"
                    onClick={() => Auth.federatedSignIn({ provider: "Google" })}
                  >
                    <img
                      src="images/google.png"
                      width="33"
                      height="36"
                      alt=""
                    />
                  </button>
                </div>

                <div className="clear20"></div>
                <div className="col-sm-12 text-center register">
                  Copyright TPH Technologies 2018
                </div>
                <div className="clearfix"></div>
              </div>
            </div>
            <div className="clear40"></div>
            <div className="clearfix"></div>
          </div>
        </div>

        <div className="col-sm-12 login_bg mbile_view">
          <div className="login_box">
            <div className="mobile_login_top">Freelance Portal</div>
            <div className="mobile_logo_login">
              <img src="images/logo2.svg" alt="" />
            </div>
            <div className="clear20"></div>
            <div className="col-sm-12 login_mobile_middle">
              {!this.state.errormessage ? (
                ""
              ) : (
                <div>
                  <div className="clear20"></div>
                  <div className="alert alert-danger text-uppercase">
                    {this.state.errormessage}
                  </div>
                </div>
              )}
              <form onSubmit={this.handleSubmit}>
                <div className="col-sm-12 p0">
                  <input
                    name=""
                    id="email"
                    className="login_input_mobile"
                    onChange={this.handleChange}
                    type="text"
                    placeholder="Email Address"
                    defaultValue=""
                  />
                  <img
                    src="images/mobile_person.svg"
                    width="16"
                    height="16px"
                    className="user_icon"
                    alt=""
                  />
                </div>
                <div className="clear20"></div>
                <div className="col-sm-12 p0">
                  <input
                    name=""
                    id="password"
                    className="login_input_mobile"
                    onChange={this.handleChange}
                    type={this.state.showPassword ? "text" : "password"}
                    placeholder="Password"
                    defaultValue=""
                  />
                  <img
                    src="images/mobile_login_lock.svg"
                    width="18"
                    height="16px"
                    className="user_icon"
                    alt=""
                  />
                  {!this.state.showPassword && (
                    <img
                      src="images/signup/eye.svg"
                      className="pwd-mask-icon"
                      alt="Show password"
                      onClick={() => this.setState({ showPassword: true })}
                    />
                  )}
                  {this.state.showPassword && (
                    <img
                      src="images/signup/eye-off.svg"
                      className="pwd-mask-icon"
                      alt="Show password"
                      onClick={() => this.setState({ showPassword: false })}
                    />
                  )}
                </div>
                <div className="clear20"></div>
                <div className="col-sm-12 p0">
                  <select
                    name="region"
                    id="region"
                    className="region-select"
                    onChange={this.handleChange}
                  >
                    <option value="-1">Select your region</option>
                    <option value="AU">Australia</option>
                    <option value="UK">United Kingdom</option>
                  </select>
                </div>
                <div className="clear15"></div>
                <div className="col-sm-12 p0 text-right">
                  <a href="/forgot" className="login_forgot">
                    Forgot Password?
                  </a>
                </div>
                <div className="clear20"></div>

                <LoaderButton
                  block
                  bsSize="large"
                  disabled={!this.validateForm()}
                  type="submit"
                  isLoading={this.state.isLoading}
                  text="Sign in"
                  loadingText="Logging in…"
                  className="btn_login"
                />
              </form>
              <div className="col-xs-6 p0 text-left">
                <a href="/register" className="login_forgot">
                  Create Acccount
                </a>
              </div>
              <div className="col-xs-6 p0 text-right">
                <a href="/need-help" className="login_forgot">
                  Need Help?
                </a>
              </div>
              <div className="clear20"></div>
              <div className="strike">
                <span>or</span>
              </div>
              <div className="clear20"></div>
              <div className="col-sm-12 text-center register">
                Login with social media
              </div>
              <div className="clear20"></div>
              <div className="col-xs-2 col-xs-offset-4">
                <button
                  className="socialbuttons"
                  onClick={() => Auth.federatedSignIn({ provider: "Facebook" })}
                >
                  <img src="images/fb.png" width="35" height="35" alt="" />
                </button>
              </div>
              <div className="col-xs-2">
                <button
                  className="socialbuttons"
                  onClick={() => Auth.federatedSignIn({ provider: "Google" })}
                >
                  <img src="images/google.png" width="33" height="36" alt="" />
                </button>
              </div>

              <div className="clear20"></div>
              <div className="col-sm-12 text-center register">
                Copyright TPH Technologies 2018
              </div>
              <div className="clearfix"></div>
            </div>
            <div className="clear10"></div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    return (
      <div className="Signin">
        <style
          dangerouslySetInnerHTML={{
            __html: `
      .login_bg { background: url(../images/web_landingpage.png) no-repeat center center fixed #000; }
    `,
          }}
        />
        {this.state.newUser === null
          ? this.renderForm()
          : this.renderConfirmationForm()}
      </div>
    );
  }
}
